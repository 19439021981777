import React from 'react';

const BarbInfo = (
    <>
      <p>I was born and raised in Saginaw Michigan spending every summer at the family cottage on Au Sable Lake swimming, skiing, fishing and boating. Water was literally all around me. Some of my earliest memories included swimming lessons at the YMCA with neighborhood friends and my two brothers. I was on the swim team in High School and loved to swim laps to improve my strength and endurance.</p>
      <p>I earned a degree from Michigan Technological University in Civil Engineering with an emphasis in Environmental Engineering. Following graduation, I worked in Denver Colorado and Salt Lake City Utah before moving to Idaho. I worked for the Department of Energy at the Idaho National Laboratory as a project manager in the Spent Nuclear Fuel Program from 1987 through 2014. I received a MS in Technical Management from University of Idaho shortly after moving to Idaho.</p>
      <p>My husband John and I have three children. They benefited from swim lessons and were on swim teams in Idaho Falls. I became a stroke and turn judge to support the Piranhas swim team.My husband and his family have skied and hiked in the Tetons all their lives. I am a recent transplant to Idaho, 32 years ago. We purchased a home in Driggs in 2011, and moved permanently in 2018. I am excited to be a part of the Teton Valley Aquatics team to bring year-round swimming to the Teton Valley.</p>
    </>
);

export default BarbInfo