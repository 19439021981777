import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Container, Row, Col } from "react-bootstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons"
import StacyStamm from "../../images/about/stacy-stamm.jpg"
import BarbBeller from "../../images/about/barb-beller.jpg"
import RichardWeinbrandt  from "../../images/about/richard-weinbrandt.jpg"
import Megan from "../../images/about/megan-latorre.jpg"
import Doug from "../../images/about/doug-self.jpg"
import Ashley from "../../images/about/ashley-knight.jpg"
import Alva from "../../images/about/alva-parsons.jpg"
import Raneta from "../../images/about/raneta-kinney.jpg"

import AboutBoardMemberCard from "./AboutBoardMemberCard"

import { StacyInfo, BarbInfo, RichardInfo, MeganInfo, DougInfo, RanetaInfo, AlvaInfo, AshleyInfo } from "../About/TeamInfo"


const AboutTeam = () => {
  const data = useStaticQuery(graphql`
    query {
      stacy: file(relativePath: { eq: "about/stacy-stamm.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      barb: file(relativePath: { eq: "about/barb-beller.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    richard: file(relativePath: { eq: "about/richard-weinbrandt.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    swimmer: file(relativePath: { eq: "about/swimmer.png" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      megan: file(relativePath: { eq: "about/megan-latorre.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      doug: file(relativePath: { eq: "about/doug-self.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      johnBeller: file(relativePath: { eq: "about/john-beller.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      janCogbill: file(relativePath: { eq: "about/jan-cogbill.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thomasCogbill: file(relativePath: { eq: "about/thomas-cogbill.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      annaJansen: file(relativePath: { eq: "about/anna-jansen-gunderson.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wrayLandon: file(relativePath: { eq: "about/wray-landon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      steveLobst: file(relativePath: { eq: "about/steven-lobst.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      debbieLobst: file(relativePath: { eq: "about/debbie-lobst.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      billLeake: file(relativePath: { eq: "about/bill-leake.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      barbLindquist: file(relativePath: { eq: "about/barb-lindquist.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      evaMarmsater: file(relativePath: { eq: "about/eva-marmsater.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      brianMcdermott: file(relativePath: { eq: "about/brian-mcdermott.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dianaMiddleton: file(relativePath: { eq: "about/diana-middleton.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      scottPaulson: file(relativePath: { eq: "about/scott-paulson.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      katrinaRunyan: file(relativePath: { eq: "about/katrina-runyan.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bonnieSelf: file(relativePath: { eq: "about/bonnie-self.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      annaTrentadue: file(relativePath: { eq: "about/anna-trentadue.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lindaUnland: file(relativePath: { eq: "about/linda-unland.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      amyVerbeten: file(relativePath: { eq: "about/amy-verbeten.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      danVerbeten: file(relativePath: { eq: "about/dan-verbeten.png" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bobWhipple: file(relativePath: { eq: "about/bob-debbie-whipple.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lynnFoxx: file(relativePath: { eq: "about/lynn-foxx.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      coryHatch: file(relativePath: {eq: "about/cory-hatch.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      vanessaEngle: file(relativePath: {eq: "about/vanessa-engle.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ashleyKnight: file(relativePath: {eq: "about/ashley-knight.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ranetaKinney: file(relativePath: {eq: "about/raneta-kinney.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      alvaParsons: file(relativePath: {eq: "about/alva-parsons.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 270, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    swimmer: file(relativePath: {eq: "about/swimmer.png"}) {
      childImageSharp {
        fluid(maxWidth: 270, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `)
  
  return (
    <Container
      fluid
      style={{
        backgroundColor: "#558C8F",
      }}
      className="mt-4"
    >
      <Container className="pt-4">
        <Row className="py-4">
          <Col
            style={{
              color: "white",
              textAlign: "center",
            }}
          >
            <h1>Meet our team</h1>
            <p>
              <strong>
                Click{" "}
                <FontAwesomeIcon
                  icon={faExternalLink}
                  style={{
                    color: "white",
                    fontSize: "20px",
                  }}
                  //className={styles.socialIcon}
                  aria-hidden="true"
                />{" "}
                to read their bios
              </strong>
            </p>
          </Col>
        </Row>
        <Row>
        <AboutBoardMemberCard 
            fluid={data.stacy.childImageSharp.fluid}
            alt="Stacy Stamm, Executive Director"
            teamMember="Stacy Stamm"
            teamMemberTitle="Executive Director"
            modalContent={StacyInfo}
            teamMemberPhoto={StacyStamm}
        />
        <AboutBoardMemberCard
          fluid={data.megan.childImageSharp.fluid}
          alt="Megan LaTorre, Board Director"
          teamMember="Megan LaTorre"
          teamMemberTitle="Board Chair"
          modalContent={MeganInfo}
          teamMemberPhoto={Megan}
        />
        <AboutBoardMemberCard 
            fluid={data.barb.childImageSharp.fluid}
            alt="Barb Beller, Board Chair"
            teamMember="Barb Beller"
            teamMemberTitle="Board Vice Chair"
            modalContent={BarbInfo}
            teamMemberPhoto={BarbBeller}
        />
        
          {/* <AboutBoardMemberCard
          fluid={data.lynnFoxx.childImageSharp.fluid}
          alt="Lynn Foxx Pease, Board Secretary"
          teamMember="Lynn Foxx Pease"
          teamMemberTitle="Board Secretary"
          modalContent={LynnInfo}
          teamMemberPhoto={Lynn}
        /> */}

{/* <AboutBoardMemberCard 
            fluid={data.coryHatch.childImageSharp.fluid}
            alt="Cory Hatch"
            teamMember="Cory Hatch"
            teamMemberTitle="Secretary"
            modalContent={CoryInfo}
            teamMemberPhoto={Cory}
        /> */}
        <AboutBoardMemberCard 
            fluid={data.richard.childImageSharp.fluid}
            alt="Richard Weinbrandt"
            teamMember="Richard Weinbrandt"
            teamMemberTitle="Board Director"
            modalContent={RichardInfo}
            teamMemberPhoto={RichardWeinbrandt}
        />

        <AboutBoardMemberCard 
            fluid={data.doug.childImageSharp.fluid}
            alt="Doug Self"
            teamMember="Doug Self"
            teamMemberTitle="Liaison to the City of Driggs"
            modalContent={DougInfo}
            teamMemberPhoto={Doug}
        />
        <AboutBoardMemberCard 
            fluid={data.ranetaKinney.childImageSharp.fluid}
            alt="Raneta Kinney"
            teamMember="Raneta Kinney"
            teamMemberTitle="Board Director"
            modalContent={RanetaInfo}
            teamMemberPhoto={Raneta}
        />
        <AboutBoardMemberCard 
            fluid={data.ashleyKnight.childImageSharp.fluid}
            alt="Ashley Knight"
            teamMember="Ashley Knight"
            teamMemberTitle="Board Director"
            modalContent={AshleyInfo}
            teamMemberPhoto={Ashley}
        />
        <AboutBoardMemberCard 
            fluid={data.alvaParsons.childImageSharp.fluid}
            alt="Alva Parsons"
            teamMember="Alva Parsons"
            teamMemberTitle="Board Director"
            modalContent={AlvaInfo}
            teamMemberPhoto={Alva}
        />
        {/* <AboutBoardMemberCard 
            fluid={data.vanessaEngle.childImageSharp.fluid}
            alt="Vanessa Engle"
            teamMember="Vanessa Engle"
            teamMemberTitle="Board Director"
            modalContent={VanessaInfo}
            teamMemberPhoto={Vanessa}
        /> */}
        {/* <AboutBoardMemberCard 
            fluid={data.johnBeller.childImageSharp.fluid}
            alt="John Beller"
            teamMember="John Beller"
            teamMemberTitle="Advisory Council"
            modalContent={JohnInfo}
            teamMemberPhoto={John}
        />
        <AboutBoardMemberCard 
            fluid={data.janCogbill.childImageSharp.fluid}
            alt="Jan Cogbill"
            teamMember="Jan Cogbill"
            teamMemberTitle="Advisory Council"
            modalContent={JanInfo}
            teamMemberPhoto={Jan}
        />
        <AboutBoardMemberCard 
            fluid={data.thomasCogbill.childImageSharp.fluid}
            alt="Thomas H. Cogbill"
            teamMember="Thomas H. Cogbill"
            teamMemberTitle="Advisory Council"
            modalContent={ThomasInfo}
            teamMemberPhoto={Thomas}
        />
        <AboutBoardMemberCard 
            fluid={data.annaJansen.childImageSharp.fluid}
            alt="Jansen &amp; Anna Gunderson"
            teamMember="Jansen &amp; Anna Gunderson"
            teamMemberTitle="Advisory Council"
            modalContent={JansenAnnaInfo}
            teamMemberPhoto={AnnaJansen}
        />
        <AboutBoardMemberCard 
            fluid={data.steveLobst.childImageSharp.fluid}
            alt="Steve Lobst"
            teamMember="Steve Iobst"
            teamMemberTitle="Advisory Council"
            modalContent={SteveInfo}
            teamMemberPhoto={Steve}
        />
        <AboutBoardMemberCard 
            fluid={data.debbieLobst.childImageSharp.fluid}
            alt="Debbie Lobst"
            teamMember="Debbie Iobst"
            teamMemberTitle="Advisory Council"
            modalContent={DebbieInfo}
            teamMemberPhoto={Debbie}
        />
        <AboutBoardMemberCard 
            fluid={data.billLeake.childImageSharp.fluid}
            alt="Bill Leake"
            teamMember="Bill Leake"
            teamMemberTitle="Advisory Council"
            modalContent={BillInfo}
            teamMemberPhoto={Bill}
        /> 
        <AboutBoardMemberCard 
            fluid={data.wrayLandon.childImageSharp.fluid}
            alt="Wray Landon"
            teamMember="Wray Landon"
            teamMemberTitle="Advisory Council"
            modalContent={WrayInfo}
            teamMemberPhoto={Wray}
        />      

        <AboutBoardMemberCard 
            fluid={data.barbLindquist.childImageSharp.fluid}
            alt="Barb Lindquist"
            teamMember="Barb Lindquist"
            teamMemberTitle="Advisory Council"
            modalContent={BarbLInfo}
            teamMemberPhoto={Barb}
        />
         <AboutBoardMemberCard 
            fluid={data.evaMarmsater.childImageSharp.fluid}
            alt="Eva Marmsater"
            teamMember="Eva Marmsater"
            teamMemberTitle="Advisory Council"
            modalContent={EvaInfo}
            teamMemberPhoto={Eva}
        />
        <AboutBoardMemberCard 
            fluid={data.brianMcdermott.childImageSharp.fluid}
            alt="Brian McDermott"
            teamMember="Brian McDermott"
            teamMemberTitle="Advisory Council"
            modalContent={BrianInfo}
            teamMemberPhoto={Brian}
        />
        <AboutBoardMemberCard 
            fluid={data.dianaMiddleton.childImageSharp.fluid}
            alt="Daina Middleton"
            teamMember="Daina Middleton"
            teamMemberTitle="Advisory Council"
            modalContent={DiannaInfo}
            teamMemberPhoto={Diana}
        />

        <AboutBoardMemberCard 
            fluid={data.scottPaulson.childImageSharp.fluid}
            alt="Scott Paulson"
            teamMember="Scott Paulson"
            teamMemberTitle="Advisory Council"
            modalContent={ScottInfo}
            teamMemberPhoto={Scott}
        />
        <AboutBoardMemberCard 
            fluid={data.katrinaRunyan.childImageSharp.fluid}
            alt="Katrina Runyan"
            teamMember="Katrina Runyan"
            teamMemberTitle="Advisory Council"
            modalContent={KatrinaInfo}
            teamMemberPhoto={Katrina}
        />
        <AboutBoardMemberCard 
            fluid={data.bonnieSelf.childImageSharp.fluid}
            alt="Bonnie Self"
            teamMember="Bonnie Self"
            teamMemberTitle="Advisory Council"
            modalContent={BonnieInfo}
            teamMemberPhoto={Bonnie}
        />
        <AboutBoardMemberCard 
            fluid={data.annaTrentadue.childImageSharp.fluid}
            alt="Anna Trentadue"
            teamMember="Anna Trentadue"
            teamMemberTitle="Advisory Council"
            modalContent={AnnaInfo}
            teamMemberPhoto={Anna}
        />
        
        <AboutBoardMemberCard 
            fluid={data.lindaUnland.childImageSharp.fluid}
            alt="Linda Unland"
            teamMember="Linda Unland"
            teamMemberTitle="Advisory Council"
            modalContent={LindaInfo}
            teamMemberPhoto={Linda}
        />
        <AboutBoardMemberCard 
            fluid={data.lindaUnland.childImageSharp.fluid}
            alt="John Unland"
            teamMember="John Unland"
            teamMemberTitle="Advisory Council"
            modalContent={JohnUInfo}
            teamMemberPhoto={Linda}
        />
        <AboutBoardMemberCard 
            fluid={data.amyVerbeten.childImageSharp.fluid}
            alt="Amy Verbeten"
            teamMember="Amy Verbeten"
            teamMemberTitle="Advisory Council"
            modalContent={AmyInfo}
            teamMemberPhoto={Amy}
        />
        <AboutBoardMemberCard 
            fluid={data.danVerbeten.childImageSharp.fluid}
            alt="Dan Verbeten"
            teamMember="Dan Verbeten"
            teamMemberTitle="Advisory Council"
            modalContent={DanInfo}
            teamMemberPhoto={Dan}
        />
 
        <AboutBoardMemberCard 
            fluid={data.bobWhipple.childImageSharp.fluid}
            alt="Bob Whipple"
            teamMember="Bob Whipple"
            teamMemberTitle="Advisory Council"
            modalContent={BobInfo}
            teamMemberPhoto={Bob}
        />
        <AboutBoardMemberCard 
            fluid={data.bobWhipple.childImageSharp.fluid}
            alt="Debbie Whipple"
            teamMember="Debbie Whipple"
            teamMemberTitle="Advisory Council"
            modalContent={DebbieWInfo}
            teamMemberPhoto={Bob}
        /> */}
        </Row>
      </Container>
    </Container>
  )
}

export default AboutTeam
