import React from "react"
import AboutTeamModal from "./AboutTeamModal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLink } from "@fortawesome/pro-solid-svg-icons"

function Modal(props) {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <>
      <span>
        <FontAwesomeIcon
          icon={faExternalLink}
          style={{
            color: "white",
            fontSize: "22px",
            cursor: "pointer"
          }}
          aria-hidden="true"
          onClick={() => setModalShow(true)}
        />
      </span>

      <AboutTeamModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        teamMemberPhoto={props.teamMemberPhoto}
        teamMemberTitle={props.teamMemberTitle}
        modalContent={props.modalContent}
        teamMember={props.teamMember}
      />
    </>
  )
}

export default Modal
