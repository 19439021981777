import React from 'react';

const CoryInfo = (
    <>
    <p>Cory Hatch is an award-winning science writer whose work has appeared in numerous publications including U.S. News and World Report, MSNBC.com and the Jackson Hole News &amp; Guide.</p>
    <p>Cory also served as the Wildlands Director for the Jackson Hole Conservation Alliance and as Executive Director of the Jackson Hole Wildlife Foundation. He currently works as a science writer for Idaho National Laboratory.</p>
    <p>Cory is a lifelong swimmer who has lifeguarded on the beaches of Cape Cod and taught distance swimming at the University of Massachusetts. He lives in Victor, Idaho with his wife and daughter.</p>
    </>
  );

export default CoryInfo;