import React from "react"
import { Modal, Row, Col } from "react-bootstrap"
import "../about.css"

function AboutTeamModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Row>
          <Col sm={6}>
            <div>
              <img
                src={props.teamMemberPhoto}
                style={{ width: "100%" }}
                alt={props.teamMember}
                className="rounded"
              />
            </div>
          </Col>
          <Col sm={6} style={{
              alignSelf: 'flex-end'
          }}>
            <h3>{props.teamMember}</h3>
            <h4 style={{ fontStyle: 'italic', fontWeight: '200'}}>{props.teamMemberTitle}</h4>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        {props.modalContent}
      </Modal.Body>
    </Modal>
  )
}

export default AboutTeamModal
