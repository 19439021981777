import React from "react"
import { Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/about.css"
import AboutTabs from "../components/About/AboutTabs"
import AboutWaterSection from "../components/About/AboutWaterSection"
import AboutCarousel from "../components/About/AboutCarousel"
import AboutTeam from "../components/About/AboutTeam"

const AboutPage = () => (
  <Layout>
    <SEO title="About Teton Valley Aquatics" />
    <Container fluid>
      <AboutTabs />
      
      <AboutWaterSection>
        <AboutCarousel />
      </AboutWaterSection>

      <AboutTeam />

    </Container>
  </Layout>
  )

// export const data = graphql`
// query {
//   aboutSwimmer: file(relativePath: { eq: "about-tabs-swimmer.jpg" }) {
//     childImageSharp {
//       fluid(maxWidth: 982, quality: 80) {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// }
// `

export default AboutPage;
