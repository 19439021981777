import React from 'react';

const RichardInfo = (
    <>
    <p>Richard Weinbrandt has worked as an independent consultant to the petroleum industry for over 40 years, primarily in the areas of numerical simulation, reservoir description, and the development and application of enhanced oil recovery processes.</p>
    <p>Dr. Weinbrandt received his Ph.D. in Petroleum Engineering from Stanford University in 1972 following B.S. and M.S. degrees in Mechanical Engineering at the University of California at Berkeley. He is a registered Professional Petroleum Engineer in the State of California.</p>
    <p>He has been involved in athletics all of his life and feels that the combination of athletics, academics and a professional career is critical to leading a balanced life. He started with age group swimming and Little League baseball which his father coached, swam in high school and as a freshman at Berkeley. In 1964 he left Berkeley for a year and moved to the North Shore of Oahu to surf the largest waves ridden at the time. On his return to Berkeley he switched sports to boxing, earning 2 varsity letters and boxing undefeated his senior year. Post college he participated in softball and flag football and raced motorcycles professionally in flat track, motocross and the Southern California desert. His triathlon career started in 1972 and continues today. His focus has been on the Ironman triathlons, completing over 70 IM’s so far, including 15 finishes at the World Championships in Kona. For the years 2012, 13 and 14 he ranked in the top 1% of all athletes in his age group in the world.</p>
    <p>Community service and philanthropy is a priority for Richard and for Peaked Sports which he owns. While his children were growing up in Jackson he coached in the kids ski and soccer programs. He was one of the founding board members of the Community Foundation of Teton Valley and the race director of the Tin Cup Challenge for the first 5 years of the event. He has served on the City of Driggs’ Design Review and Advisory Committee for over 10 years. He was one of the founding board members of Mountain Bike the Tetons and continues to serve on that board. One of the MBT programs is the kid’s mountain bike program and Peaked Sports is a sponsor of the TRC team. He is one of the founding board members of Teton Valley Aquatics and continues to serve on that board.</p>
    </>
  );

export default RichardInfo;