import React from 'react';

const JansenAnnaInfo = (
    <>
    <p>Jansen is a web developer and landscape photographer who grew up in Sandy, Utah. He graduated from Southern Utah University in 2000 and moved to Wilson, Wyoming in 2007. Anna is a physician assistant from Charlotte, North Carolina. She graduated from Appalachian State in Boone, NC in 2004 and received her physician assistant certification from Idaho State University in 2012.</p>
    <p>Anna and Jansen met in Jackson, Wyoming and lived in a yurt in Kelly, Wyoming before Anna attended P.A. school in Pocatello. In 2012 they moved to Victor, Idaho to raise their two children. Anna and Jansen are avid outdoor enthusiasts who enjoy skiing, rock climbing, cycling and mountain biking, kayaking, and backpacking.</p>
    <p>While on their honeymoon in Iceland, Anna and Jansen appreciated the benefits that community pools and hot springs offered to the local communities. The facilities provided a community meeting spot for socializing, relaxing, and exercising – especially during the long Icelandic winter. Anna and Jansen are excited to help bring similar facilities to Teton Valley.</p>
    </>
  );

export default JansenAnnaInfo;