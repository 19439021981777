import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

import Img from "gatsby-image"
import { Container, Row, Col, Tab, Nav } from "react-bootstrap"

import "../about.css"

const AboutTabs = () => {

    const data = useStaticQuery(graphql`
    query {
        aboutSwimmer: file(relativePath: { eq: "about-tabs-swimmer.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 982, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        annualReport: file(relativePath: { eq: "TVA-Annual-Report-2021.png" }) {
          childImageSharp { 
            fluid(maxWidth: 570, quality: 60) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
`)

return (
    <Container style={{
        marginTop: '4rem  '
      }}>
<Row className="my-4">
        <Col sm={3} className="d-none d-md-block">
          <Img fluid={data.aboutSwimmer.childImageSharp.fluid} alt="Senior Swimmer" className="rounded my-4" />
        </Col>
        <Col xs={{order: 'first'}} md={{order: 'last'}} className="rounded tab-main-background my-4 col-sm-9" style={{
          flex: 1,

        }}>
          <Tab.Container id="about-tabs" defaultActiveKey="mission">
            <Row style={{
              height: '100%'
            }}>
              <Col sm={4} style={{
                paddingRight: 0,
                paddingLeft: 0
              }}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="mission">
                      Mission &amp; Vision
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="history">
                      History
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="timeline">
                      Timeline
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="didyouknow">
                      Did you know?
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="reports">
                      Reports
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={8} style={{
                paddingLeft: 0,
                paddingRight: 0,
                display: 'flex',
                height: '100%',
                minHeight: '100%',
              }}>
                <Tab.Content>
                  <Tab.Pane eventKey="mission">
                    <p className="tab-content">Partnering with the City of Driggs and the Community of Teton Valley to optimize resources for designing, building, and operating a successful aquatic facility. Thus building a healthier community through the power of water.</p>
                    <p className="tab-content">Our Vision is fulfilling the dream of year-round aquatic access for Teton Valley.</p>
                  </Tab.Pane>
                </Tab.Content>
                <Tab.Content>
                  <Tab.Pane eventKey="history">
                    <p className="tab-content" style={{marginBottom: 0}}>Teton Valley Aquatics was formed in 2016 in response to community support and the need for an organization to take the lead in pursuing the dream to have an aquatics facility in Teton Valley. The 2014 Teton County, Idaho Recreation Master Plan showed the top priority of survey respondents was for a Community Recreation Center, with 93% of those respondents stating the most important feature would be an indoor swimming facility. Teton Valley Aquatics has since partnered with the City of Driggs to bring this needed facility to life. In the last five years, TVA has worked from the ground up establishing relationships around our community to build the framework that is needed to pursue a project of this size.</p>
                    <p className="tab-content">
                    In 2019, we completed a feasibility study identifying the options for our future facility. In 2020, we evaluated eight locations within the Driggs City limits and selected a final site near 5th St and Ski Hill Road. In 2021, donation of land for the future facility was completed to the City of Driggs, and in 2022 drilling for geothermal hot water will kick off. While investing time and energy into this process we are also offering beginner swim lessons, water safety education, and Life Jacket Loaner Stations, engaging over 500 community members annually.</p>
                  </Tab.Pane>
                </Tab.Content>
                <Tab.Content>
                  <Tab.Pane eventKey="timeline">
                    <p><strong style={{fontSize: 'larger'}}>Timeline to Teton Valley Aquatic Center</strong><br/><hr/>
                    
                    <strong>May 2016</strong><br/>
                    Started TVA non-profit process.<br />
                    Engaged USA Swimming for research and Enterprise Plan.<br />
                    Established community steering committee.<br/>
                    <hr />
                    <strong>December 2016</strong><br />
                    Launched website.<br/>
                    Opened community survey.<br />
                    <hr />
                    <strong>February 2017</strong><br />
                    Closed survey<br />
                    Over 1,000 completed surveys, representing over 3400 members of the Teton Valley community!<br />
                    Continued feasibility research.<br />
                    Conducted stakeholder meeting.<br />
                    <hr />
                    <strong>March-April 2017</strong><br />
                    Conducted community outreach and meetings/events to go over Enterprise Plan results (what pool USA Swimming thinks we need if money is no object).<br />
                    Survey results (what the community thinks we need if money is no object).<br />
                    <hr />
                    <strong>April 2017</strong><br />
                    Conducted a philanthropic feasibility study to determine capacity of private philanthropic donations towards our goal.<br />
                    <hr />
                    <strong>Summer 2017</strong><br />
                    Continued researching comparison pools.<br />
                    Budget comparisons.<br />
                    Successfully participated in Tin Cup Challenge.<br />
                    TVA received federal 501(c)3 status.<br />
                    <hr />
                    <strong>Fall 2017</strong><br />
                    Hired executive director to assist board in implementing strategic plan based on recommendations of philanthropic feasibility consultant.<br />
                    Began to implement Teton Valley Swims programming for residents of the greater Teton Valley, ID area.<br />
                    <hr />
                    <strong>Winter 2017</strong><br />
                    Begin to implement learn to swim programming for Teton Valley residents<br />
                    <hr />
                    <strong>January-March 2018</strong><br />
                    Engage facility feasibly study for specific build and maintenance cost for overall project, as well as stepping stone projects.<br />
                    Contract a design build study for initial plans/drawings of stepping stone projects.<br />
                    <hr />
                    <strong>Fall 2018-Spring 2020</strong><br />
                    Worked with key partners and consultants to produce a facility feasibility study including estimates for construction and operation costs to inform planning and design of the aquatics facility.
                    <hr />
                    <strong>Summer 2020-Fall 2021</strong> <br />
                    Worked with the City of Driggs and local landowners to identify the best location for the future facility. Squeak and Patti Melehes and Ball Ventures, Inc generously donated a total of 7 acres of land to the City of Driggs in the fall of 2021 for the final facility. This acreage is located near the corner of Ski Hill Road and 5th Street, just north of the 5th Street Skatepark.<hr />
                    
                    <strong>Fall 2022/Spring 2023</strong><br /> Drill a geothermal well to determine availability of geothermal (hot) water for use at the facility.<hr /> 

<strong>2022-2024</strong><br />Begin capital campaign, facility design, and construction!

                    </p>
                  </Tab.Pane>
                </Tab.Content>
                <Tab.Content>
                  <Tab.Pane  eventKey="didyouknow">
                    <p>Research has shown that participation in formal swimming lessons can reduce the risk of drowning among children aged 1 to 4 years.</p>
                    <p>Drowning is responsible for more deaths in children ages 1-4 than any other cause except birth defects.</p>
                    <p>Minorities and individuals of lower socio-economic status drown at greater rates than white, with greatest disparity between ages 5-18.</p>

                    {/* <p><strong>Unemployed and underemployed in Driggs is 56% and in Victor is 35%.</strong><br />
                    These are the individuals who cannot afford to travel for swim lessons. The United Way has created ALICE reports studying "Asset Limited, Income Constrained, Employed" Individuals. In other words, they are studying the underemployed. Their most recent study of the Pacific Northwest includes studies of Teton County, Idaho (page 258 of document, 268 if in PDF). Percentage unemployed and underemployed in Driggs is 56% and in Victor is 35%. These are the individuals who cannot afford to travel for swim lessons. We can significantly make a difference to these individuals, and potentially SAVE LIVES by creating an aquatics center here.</p>
                    <hr />
                    <p><strong>Aquatics center in our valley will bring 4 full time professional level jobs, as well as 12-24 part time jobs.</strong><br />
                    In addition to the unemployed and underemployed studies, the ALICE report goes further and finds what it has determined as the "survival budget" to actually pay your rent, eat 3 meals (but does not account for savings.) In Teton County, it determines this wage to be $9.61 for a single person working full time, and $31.72 for a person to raise enough to support a traditional 4 person family (defined by United Way as 2 adults, one preschooler and one infant). Having an aquatics center in our valley will bring 4 full time professional level jobs, as well as 12-24 part time jobs that will pay at/above these "survival wage" levels, helping to bring job stability to our area, and allowing working families to make a life here.</p>
                    <hr />
                    <p><strong>United Way Alice Report</strong></p>
                    <ol>
                      <li>Drowning is responsible for more deaths in children ages 1-4 than any other cause except birth defects.</li>
                      <li>Participation in formal swimming lessons can reduce the risk of drowning among children aged 1 to 4 years.</li>
                      <li>Minorities and individuals of lower socio-economic status drown at greater rates than whites, with greatest disparity between ages 5-18.</li>
                    </ol>
                    <hr />
                    <p style={{
                      fontSize: 'smaller'
                    }}><strong>Sources</strong><br />1. Centers for Disease Control and Prevention, National Center for Injury Prevention and Control. Web-based Injury Statistics Query and Reporting System (WISQARS) [online]. [cited 2016Nov 28]. Available from: URL: http://www.cdc.gov/injury/wisqars.<br />
                    2. Brenner RA, Taneja GS, Haynie DL, Trumble AC, Qian C, Klinger RM, Klevanoff MA. Association between swimming lessons and drowning in childhood: A case-control study. Archives of Pediatrics &amp; Adolescent Medicine 2009;163(3):203-10.<br />
                    2. Yang L, Nong QQ, Li CL, Feng QM, Lo SK. Risk factors for childhood drowning in rural regions of a developing country : a case-control study. Injury Prevention, 2007; 13:178-182<br />3. Gilchrist J, Parker EM. Racial/ethnic disparities in fatal unintentional drowning among persons aged ≤29 years—United States, 1999–2010. MMWR 2014;63:421–6.
                    </p> */}
                  </Tab.Pane>
                </Tab.Content>
                <Tab.Content>
                  <Tab.Pane eventKey="reports">
                    <div style={{width: '100%'}}>
                    <div style={{padding: '20px'}}>
                    <a href="https://assets.ctfassets.net/kjisl26qqle3/5G61NFmk4rVlx1PiATfpY3/cab2f3a4cd5a91b37962aec44cd31c46/TVA-Annual-Report-2021.pdf" target="_blank" rel="noopener noreferrer">
                    <p style={{marginBottom: 0}}><strong>Download the 2021 Annual Report</strong><br />1.6 MB PDF</p>                      
                  <Img style={{width: '570px'}} fluid={data.annualReport.childImageSharp.fluid} className="rounded" alt="Teton Valley Aquatics Annual Report 2021"/></a>
                  </div>
                  </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
      </Container>
)
}

export default AboutTabs