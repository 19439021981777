import React from 'react';

const BillInfo = (
  <>
  <p>Over 35 years of management and engineering experience. Was Director of Public Works/Infrastructure and Engineering at the Idaho National Laboratory’s 890 square mile site outside of Idaho Falls. Additionally, was the Director of Planning and Budgeting for the Environmental Cleanup Program for the National Laboratory. Has a broad experience in Personnel management. His management style embraces respect for others opinions, focuses on the facts, strives to understand all aspects of an issue before finalizing solutions; and get the best value while seeking out opportunities to multi-faceted challenges.</p>
  <ul>
  <li>U.S. Navy Submarine Service Veteran</li>
<li>Bachelor of Science Degree in Mechanical Engineering</li>
<li>Training Supervisor for Nuclear Submarine Operations</li>
<li>Outdoor Enthusiast, Certified Sailing Instructor</li>
<li>Married 46 years to his High School Sweetheart</li>
  </ul>
  <p>Many Teton Valley residents and visitors would like to have local access to aquatic recreation opportunities which is currently lacking a swimming pool accessible to the general public.</p>
  </>
 );

export default BillInfo;