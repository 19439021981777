import React from 'react';

const StacyInfo = (
    <>
    <p>Stacy Stamm's background includes a master's degree in Biomechanics from the University of Alberta and several years developing footwear for Nike. Most recently, Stamm led the Grand Targhee Resort Guest Services Department.</p>
    <p>Stamm is an accomplished swimmer. Her aquatics experience includes eight years as a synchronized swimmer, five years lifeguarding, and over a decade swimming on masters swim teams.</p>
    <p>Stamm and her husband moved to Teton Valley in 2018 in order to be closer to the mountains. She and her husband recently finished construction of a home in Darby.</p>
    </>
  );

export default StacyInfo;