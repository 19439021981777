import React from 'react';

const SteveInfo = (
  <>
  <p>Steve retired on October 1, 2016 after 43 years of service to the United States of America. He was appointed Deputy Superintendent of Yellowstone National Park in August 2011 where he directed day-to-day operations of the 2.2 million-acre park.</p>
  <p>Steve’s career with the National Park Service began as a Student Engineer in 1971 and in 1974. His first career assignment was as a Civil Engineer at the Denver Design Center after receiving a degree in Civil Engineering from Virginia Tech. In 1979, Steve moved to Yellowstone where he began his career in park operations.</p>
  <p>Steve and his wife Debbie have been married for 42 years. She is a successful swim coach and instructor, and they have three grown children and four grandchildren. They currently reside in Driggs, Idaho and prefer to call the Greater Yellowstone home. They enjoy traveling, hiking, biking, skiing, bird hunting, and spending time with family. Steve has been swimming ever since he can remember. Steve was on the swim club and was a lifeguard in high school. Steve believes swimming is a life-long skill and activity that everyone should be skilled at to eliminate the fear of being in the water.</p>
  </>
 );

export default SteveInfo;