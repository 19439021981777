import React from 'react';

const WrayInfo = (
  <>
  <p>Wray Landon has been a part-time Teton Valley resident since 2009 and a full-time resident since 2016. His attachment to this area is based on its incredible access to outdoors activities. He and his wife Lani are building their retirement home in Driggs.</p>
  <p>Wray appreciates the benefits of a community aquatic facility since both of his children grew up with swim racing.</p>
  <p>In 2016, Wray retired from the Idaho National Laboratory as their Director of Engineering. He has a BS in mechanical engineering, an MBA, and is an Idaho professional engineer.</p>
  <p>Wray believes his engineering and construction experience will be an asset as the Teton Valley Aquatics Center moves from a concept to reality.
Wray appreciates the benefits of a community aquatic facility. Both of his children grew up with swim racing.</p>
<p>Since 2017, Wray has served as TVA’s secretary. He understands the challenges of TVA’s mission and looks forward to moving it forward. The key challenges Wray will be focusing on are: establishing a monetary reserve, completing the Facility Feasibility Study, and obtaining a facility site.</p>
  </>
 );

export default WrayInfo;