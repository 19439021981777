import React from "react"
import { Container, Row, Col, Carousel } from "react-bootstrap"
import Cushmans from "../../images/John_Jeanine_Cushman@2x.png"
import Hyrum from "../../images/about/hyrum-johnson.png"

const AboutCarousel = () => (
  <div
    style={{
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
    }}
  >
    <div
      style={{
        width: "100%",
        height: "auto",
        padding: "5% 0 5% 0",
      }}
    >
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Container>
          <Row>
            <Col
              className="rounded"
              style={{
                backgroundColor: "#0033667e",
                padding: "50px",
              }}
            >
              <h1
                style={{
                  color: "white",
                  textAlign: "center",
                }}
              >
                Hear it from our community
              </h1>
              <Carousel interval={9000}>
                <Carousel.Item>
                  <div>
                    <img
                      className="d-block mx-auto"
                      src={Cushmans}
                      alt="First slide"
                      style={{
                        width: "173px",
                        height: "173px",
                      }}
                    />
                  </div>
                  <div
                    className="rounded"
                    style={{
                      backgroundColor: "white",
                      marginTop: "-70px",
                    }}
                  >
                    <Carousel.Caption
                      style={{
                        position: "unset",
                        paddingTop: "100px",
                      }}
                    >
                      <h3>John &amp; Jeanine Cushman</h3>
                      <p
                        style={{
                          color: "#003366",
                        }}
                      >
                        “We believe in the mission of Teton Valley Aquatics. Our
                        hope is that our gift will inspire others to give to the
                        project for a year round aquatics facility in our
                        valley.”
                      </p>
                    </Carousel.Caption>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div>
                    <img
                      className="d-block mx-auto rounded-circle"
                      src={Hyrum}
                      alt="First slide"
                      style={{
                        width: "173px",
                        height: "173px",
                      }}
                    />
                  </div>
                  <div
                    className="rounded"
                    style={{
                      backgroundColor: "white",
                      marginTop: "-70px",
                    }}
                  >
                    <Carousel.Caption
                      style={{
                        position: "unset",
                        paddingTop: "100px",
                      }}
                    >
                      <h3>Hyrum Johnson<br/><span style={{
                          fontSize: 'smaller'
                      }}>Former Driggs Mayor</span></h3>
                      <p
                        style={{
                          color: "#003366",
                        }}
                      >
                        “Whether for teaching children the life skill of swimming, for critical therapy for the injured, or for good clean fun, an aquatic center will fill many needs, and serve a broad range of our community members. The City of Driggs is pleased to partner with Teton Valley Aquatics to bring this dream to life for the people of our community.”
                      </p>
                    </Carousel.Caption>
                  </div>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </div>
)

export default AboutCarousel
