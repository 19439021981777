import React from 'react';

const FieldingInfo = (
      <>
        <p>I grew up in New Hampshire prior to moving to the area 12 years ago. While growing up there, summer days on the beach turned into competitive swimming in the winter starting when I was 9 years old. Summer jobs as a lifeguard and swim instructor followed as well through college, where I oversaw the town pool every summer. I graduated from St. Lawrence University in 2007 with a degree in Economics, where I met my wife Kim. Needing to learn to snowboard in order to keep up at Whiteface, I quickly fell in love with water in snow form all over again. I relocated here for a ski season and never left.</p>
<p>After working in various accounting positions throughout the valley and Jackson, I started my own custom knife company two years ago, TC Cutlery. I specialize in forged stainless steel kitchen cutlery.</p>
<p>Having spent the majority of my life in the water, bringing that joy to Teton Valley is a natural fit. I believe every child should learn to swim regardless of means, and get to enjoy the benefits of swimming and other aquatic pursuits. I am excited to be a part of TVA and hope to assist in seeing this project through, giving back to the community we love and call home.</p>
      </>
  )

  export default FieldingInfo