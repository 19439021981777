import React from 'react';

const WrayInfo = (
  <>
  <p>I always loved being in a pool growing up. I l taught myself to swim with a little encouragement from my brother in a neighborhood motel pool. Who knew then that swimming would be such a huge part of my future.</p>
  <p>I worked with my daughter at age one to be “water loving” and as water safe as a one year old can be. That transitioned into volunteering to create a school swim program I instructed at Chico Hot Springs. I formalized that instruction experience of already nine years to become a Red Cross Certified Water Safety Instructor, teaching Red Cross lessons for 29+ years.</p>
  <p>I then became head coach of the Stingrays. It was incredibly rewarding with 10 Wyoming state championships and being awarded WY Coach of the Year two years in a row.</p>
  <p>As much as I love coaching, teaching offers other rewards. When I work with a child who is terrified of the water, and then discovers that water is fun and they can float, I see them discovering a whole new wonderful world! This is as rewarding as a state championship because I know I have made a difference in their life and world. I find it exciting and necessary that we continue these same experiences with this project of building an aquatic center in Teton Valley.
</p>
  </>
 );

export default WrayInfo;