import React from "react"
import Img from "gatsby-image"

import { Col } from "react-bootstrap"
import AboutTeamModalButton from "./AboutTeamModalButton"

const AboutBoardMemberCard = (props) => (
    <Col sm={3} className="team-card mb-4">
               <Img
                 fluid={props.fluid}
                 alt={props.alt}
                 className="rounded-circle"
               />
               <p className="team-card-name">
                 {props.teamMember}
                 
                 <br />
                 <span>{props.teamMemberTitle}</span><br/>
                 <AboutTeamModalButton
                   teamMemberPhoto={props.teamMemberPhoto}
                   teamMemberTitle={props.teamMemberTitle}
                   teamMember={props.teamMember}
                   modalContent={props.modalContent}
                 />
               </p>
             </Col>
   )

export default AboutBoardMemberCard


